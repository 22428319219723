import vueFormGenerator from 'vue-form-generator';

export const model = {
	AutypeId: 0,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameLokalSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localsk2557',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameLokalCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localcz2559',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameLokalPl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localcz2560',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameLokalEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameLokalDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localde2563',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AutypeNameLokalHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localhu2567',
				},
			},

			{
				type: 'switch',
				model: 'AutypeHasExtendedForm',
				i18n: {
					label: 'ithasalongform',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'BedroomCountMin',
				i18n: {
					label: 'minimumnumberof',
				},
				styleClasses: 'quarter-width',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'BedroomCountMax',
				i18n: {
					label: 'maximumnumberof',
				},
				styleClasses: 'quarter-width',
			},
		],
	},
];
